import { ChangeEvent, useCallback, useRef, useState } from 'react';

import {
  Box,
  Button,
  buttonClasses,
  ClickAwayListener,
  FormControl,
  FormHelperText,
  Grow,
  InputLabel,
  inputLabelClasses,
  MenuItem,
  menuItemClasses,
  MenuList,
  Paper,
  Popper,
  styled,
} from '@mui/material';

import { CheckmarkIcon, SelectArrowDownIcon } from '../../../assets/icons';
import { scrollToDropdown } from './dropdown-scroll';

type DropdownItem = {
  name: string;
  value: string;
};

type DropdownProps = {
  idToScroll?: string;
  items: DropdownItem[];
  id: string;
  onSelect: (index: number) => void;
  onClose?: () => void;
  activeItem: number | null;
  label: string;
  error?: boolean;
  helperText?: React.ReactNode;
};

export const Dropdown = ({
  idToScroll,
  items,
  id,
  onSelect,
  activeItem,
  label,
  error,
  helperText,
  onClose,
}: DropdownProps) => {
  const menuRef = useRef<HTMLButtonElement>(null);
  const [isOpen, setOpen] = useState(false);

  const selectedValue =
    activeItem !== null ? items[activeItem].name : undefined;
  const useFocusedStyle = isOpen || !!selectedValue;

  const onIndustrySelectionChange = useCallback(
    (evt: ChangeEvent<HTMLSelectElement>) => {
      const val = evt.target.value;
      let idx = items.findIndex((opt) => opt.value === val);
      idx = idx >= 0 ? idx : 0;

      onSelect(idx);
    },
    [items, onSelect],
  );

  const scrollToDropdownRef = useRef(
    scrollToDropdown(idToScroll ?? id, items, {
      dropdownButtonHeight: 56,
    }),
  );

  return (
    <Box sx={{ position: 'relative' }}>
      <DropdownFormControl
        variant="filled"
        focused={useFocusedStyle}
        fullWidth
        error={error}
        id={id}
      >
        <MenuButton
          id={`${id}-button`}
          aria-labelledby={`${id}-button-label`}
          endIcon={<SelectArrowDownIcon />}
          variant="contained"
          ref={menuRef}
          onClick={() => {
            if (isOpen === false && idToScroll) {
              scrollToDropdownRef.current();
            }

            setOpen(!isOpen);
          }}
          sx={{
            [`&.${buttonClasses.root}`]: {
              borderColor: error
                ? 'var(--color-light-red)'
                : isOpen
                  ? 'var(--color-dark-coal)'
                  : '',
            },

            svg: {
              transform: `rotate(${isOpen ? 180 : 0}deg)`,
            },
          }}
        >
          {selectedValue}
        </MenuButton>

        {/* put label after menu button so we can style it using the 'next sibling' css selector */}
        {selectedValue === undefined && (
          <InputLabel id={`${id}-button-label`} htmlFor={`${id}-button`}>
            {label}
          </InputLabel>
        )}

        {/* the native select overlay is only used on mobile viewports */}
        <NativeSelectOverlay
          onChange={onIndustrySelectionChange}
          aria-labelledby={`${id}-button-label`}
        >
          {items.map((opt) => (
            <option key={opt.value} value={opt.value}>
              {opt.name}
            </option>
          ))}
        </NativeSelectOverlay>
        <DropdownMenu
          items={items}
          open={isOpen}
          anchor={menuRef.current}
          onClose={() => {
            if (onClose) {
              onClose();
            }
            setOpen(false);
          }}
          onSelect={(newIndex) => {
            setOpen(false);
            onSelect(newIndex);
          }}
          name={`${id}-menu`}
          labelledby={`${id}-button`}
          activeItem={activeItem}
          error={error}
        />
        <FormHelperText sx={{ marginLeft: '0' }}>{helperText}</FormHelperText>
      </DropdownFormControl>
    </Box>
  );
};

const DropdownFormControl = styled(FormControl)(({ theme }) => ({
  [`.${inputLabelClasses.root}`]: {
    zIndex: 'auto',
  },

  [`.${inputLabelClasses.filled}`]: {
    ...theme.typography.copySm,
    color: 'var(--color-medium-grey)',
    transform: 'translate(16px, 19px) scale(1)',

    [`&.${inputLabelClasses.error}`]: {
      color: 'var(--color-light-red)',
    },

    [`&.${inputLabelClasses.focused}:not(.${inputLabelClasses.error})`]: {
      color: 'var(--color-medium-grey)',
    },
  },
}));

const MenuButton = styled(Button)(({ theme }) => ({
  fontSize: `${theme.typography.copySm.fontSize} !important`,
  lineHeight: `${theme.typography.copySm.lineHeight} !important`,
  fontWeight: theme.typography.fontWeightRegular,
  backgroundColor: 'white !important', // don't let hovers overwrite the bg color
  border: '1px solid var(--color-medium-grey)',
  display: 'flex',
  justifyContent: 'flex-start',
  color: 'var(--color-dark-coal)',
  textAlign: 'left',
  transition: 'border-color 0.2s ease',
  width: '100%',
  height: '56px',
  padding: '16px 12px 15px 16px !important',

  '&:focus, &:hover, &:has(~ select:focus)': {
    // use the outline instead of border to prevent the page from jumping
    outline: '2px solid var(--color-dark-coal)',
    outlineOffset: '-1px',
  },

  [`.${buttonClasses.endIcon}`]: {
    position: 'absolute',
    right: '20px',
    top: '24px',
    margin: 0,
  },

  svg: {
    transition: 'transform var(--transition-duration) var(--transition-timing)',
    color: 'var(--color-medium-grey)',
  },
}));

const NativeSelectOverlay = styled('select')(({ theme }) => ({
  WebkitAppearance: 'none', // required for safari to modify height
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  opacity: 0,
  border: 0,

  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

type DropdownMenuProps = {
  items: { name: string; value: string }[];
  open: boolean;
  name: string;
  labelledby: string;
  anchor: HTMLButtonElement | null;
  onClose: () => void;
  onSelect: (index: number) => void;
  activeItem: number | null;
  error?: boolean;
};

const DropdownMenu = ({
  open,
  items,
  anchor,
  onClose,
  onSelect,
  name,
  labelledby,
  activeItem,
  error,
}: DropdownMenuProps) => (
  <DropdownPopper
    open={open}
    anchorEl={anchor}
    role={undefined}
    placement="bottom-start"
    transition
    disablePortal
  >
    {({ TransitionProps }) => (
      <Grow
        {...TransitionProps}
        style={{
          transformOrigin: 'center top',
          width: '100%',
          inset: 'unset !important',
        }}
      >
        <DropdownPaper isError={error}>
          <ClickAwayListener onClickAway={onClose}>
            <DropdownMenuList
              autoFocus
              variant="selectedMenu"
              id={name}
              aria-labelledby={labelledby}
            >
              {items.map((item, idx) => (
                <DropdownMenuItem
                  disableRipple
                  key={item.value}
                  onClick={() => onSelect(idx)}
                  isActive={idx === activeItem}
                >
                  {item.name}
                  {idx === activeItem && (
                    <CheckmarkIcon
                      style={{ color: 'var(--color-light-red)' }}
                    />
                  )}
                </DropdownMenuItem>
              ))}
            </DropdownMenuList>
          </ClickAwayListener>
        </DropdownPaper>
      </Grow>
    )}
  </DropdownPopper>
);

const DropdownPopper = styled(Popper)({
  inset: 'unset !important',
  width: '100%',
  transform: 'translate(0px, 55px) !important', // align with border of parent container
  zIndex: 1,
});

const DropdownPaper = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'isError',
})((props: { isError?: boolean }) => ({
  backgroundColor: 'var(--color-white)',
  boxShadow: `
    inset -1px -1px 0px 0px var(${
      props.isError ? '--color-light-red' : '--color-dark-coal'
    }),
    inset  1px  0px 0px 0px var(${
      props.isError ? '--color-light-red' : '--color-dark-coal'
    })`,
}));

const DropdownMenuList = styled(MenuList)({
  paddingTop: '5px',
  paddingBottom: '5px',

  '&:focus': {
    outline: 'none',
  },

  '&::after': {
    content: '" "',
    display: 'block',
    height: '0',
    backgroundColor: 'red',
    position: 'absolute',
    top: 0,
    left: '14px',
    right: '14px',
    borderTop: '1px solid var(--color-medium-light-grey)',
  },
});

const DropdownMenuItem = styled(MenuItem, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})((props: { isActive?: boolean }) => ({ theme }) => ({
  ...theme.typography.copySm,

  paddingTop: '9px',
  paddingBottom: '9px',
  paddingLeft: '16px',
  paddingRight: '16px',
  whiteSpace: 'normal',

  color: props.isActive ? 'var(--color-light-red)' : undefined,

  '&:hover, &:focus': {
    color: 'var(--color-light-red)',
    backgroundColor: 'transparent',
  },

  [`&.${menuItemClasses.focusVisible}`]: {
    color: 'var(--color-light-red)',

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },

  svg: {
    marginLeft: '10px',
  },
}));
