/**
 * To ensure all UI localizations are translated properly, this function checks
 * the existence of a given key. It throws an error for production builds if the
 * key does not exist. During development, it just returns the key.
 */
const translate = <T extends Record<string, string | null>>(
  translations: T,
  key: keyof T,
  replacements?: { [key: string]: string },
): string => {
  const translationVal = translations[key];

  if (!translationVal) {
    // Only throw error for production builds if we explicitly set
    // MEWA_CHECK_TRANSLATIONS. Regular production builds without the
    // MEWA_CHECK_TRANSLATIONS flag will return the key. This is useful during
    // development while the translations are still a work in progress.
    if (
      process.env['NODE_ENV'] === 'production' &&
      process.env['MEWA_CHECK_TRANSLATIONS'] === 'true'
    ) {
      throw new Error('Missing translation: ' + key.toString());
    }

    return key as string;
  }

  if (typeof replacements === 'object') {
    return Object.entries(replacements).reduce(
      (translated, [key, value]) => translated.replace(`{${key}}`, value),
      translationVal as string,
    );
  }

  // Cast to string as the typechecker does not seem to recognize that the
  // `translationVal` cannot be null at this point. If it is null, the function
  // returns the key or throws an exception when checking translations is
  // enabled.
  return translationVal as string;
};

export default translate;

// Regex to extract the different link parts in the following format:
//
//     Text before link [link text] text after link.
//
const TEXT_WITH_LINK_REGEX = /(?<before>.*)(?<link>\[.*\])(?<after>.*)/;

/**
 * Works the same as `translate`, except it will also extract the `[...]` link
 * pattern and split the translated string into 3 parts.
 *
 *   - the part before the link
 *   - the link itself
 *   - the part after the link
 */
export const translateWithLink = <T extends Record<string, string | null>>(
  translations: T,
  key: keyof T,
  replacements?: { [key: string]: string },
) => {
  const translationVal = translate(translations, key, replacements);

  const regexRes = TEXT_WITH_LINK_REGEX.exec(translationVal);

  if (!regexRes || !regexRes.groups) {
    return {
      beforeLink: '',
      link: translationVal,
      afterLink: '',
    };
  }

  const res = {
    beforeLink: regexRes.groups['before'],
    link: regexRes.groups['link'],
    afterLink: regexRes.groups['after'],
  };

  // remove `[` and `]` from link annotation
  res.link = res.link.substring(1, res.link.length - 1);

  return res;
};
