import { Components, Theme } from '@mui/material';
import { buttonClasses } from '@mui/material/Button';

declare module '@mui/material/Button' {
  interface ButtonPropsSizeOverrides {
    xl: true;
  }
}

const colorTransition = (color: 'background-color' | 'color') =>
  `all 150ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, ${color} var(--transition-duration) ease-in-out`;

const button = (theme: Theme): Components<Theme>['MuiButton'] => ({
  defaultProps: {
    size: 'large',
    disableElevation: true,
    disableRipple: true,
  },
  variants: [
    {
      props: { size: 'large' },
      style: {
        ...theme.typography.copy,
        fontWeight: theme.typography.fontWeightMedium,

        [`&.${buttonClasses.outlined}`]: {
          borderWidth: 2,
        },
      },
    },
    {
      props: { size: 'medium' },
      style: {
        ...theme.typography.copy,
        fontWeight: theme.typography.fontWeightMedium,

        [`&.${buttonClasses.outlined}`]: {
          borderWidth: 2,
        },
      },
    },
    {
      props: { size: 'small' },
      style: {
        ...theme.typography.copySm,
        fontWeight: theme.typography.fontWeightMedium,

        [`&.${buttonClasses.outlined}`]: {
          borderWidth: 2,
        },
        [`&.${buttonClasses.text}`]: {
          paddingTop: '4px',
          paddingBottom: '16px',
        },
      },
    },
  ],
  styleOverrides: {
    root: {
      borderRadius: 0,
      boxShadow: 'none',
      textTransform: 'none',
      fontWeight: theme.typography.fontWeightMedium,
      position: 'relative',
      // disabling min-width because we have short button texts
      minWidth: '0',

      '&:hover, &:focus': {
        boxShadow: 'none',

        [`&.${buttonClasses.outlined}`]: {
          [`&.${buttonClasses.outlinedPrimary}`]: {
            backgroundColor: theme.palette.primary.main,
            color: 'var(--color-white)',
          },

          [`&.${buttonClasses.outlinedSecondary}`]: {
            backgroundColor: theme.palette.secondary.main,
            color: 'var(--color-white)',
          },

          [`&.MuiButton-outlinedInverted`]: {
            backgroundColor: 'var(--color-white)',
            color: theme.palette.primary.main,
          },
        },

        [`&.${buttonClasses.contained}`]: {
          [`&.${buttonClasses.containedPrimary}`]: {
            backgroundColor: theme.palette.primary.dark,
          },

          [`&.${buttonClasses.containedSecondary}`]: {
            backgroundColor: theme.palette.secondary.dark,
          },

          '&.MuiButton-containedInverted': {
            color: theme.palette.primary.dark,
          },
        },
      },

      [`&.${buttonClasses.outlinedPrimary}`]: {
        borderColor: theme.palette.primary.main,
      },

      [`&.${buttonClasses.outlinedSecondary}`]: {
        borderColor: theme.palette.secondary.main,
      },

      '&.MuiButton-outlinedInverted': {
        borderColor: 'var(--color-white)',
      },

      [`&.${buttonClasses.contained}`]: {
        padding: '16px 30px 18px',
      },

      '&.MuiButton-containedInverted': {
        color: theme.palette.primary.main,
      },

      [`&.${buttonClasses.outlined}`]: {
        padding: '14px 28px 16px',
      },

      [`&.${buttonClasses.contained}.${buttonClasses.disabled}`]: {
        color: theme.palette.background.default,
        backgroundColor: 'var(--color-medium-light-grey)',
      },

      [`&.${buttonClasses.outlined}.${buttonClasses.disabled}`]: {
        color: 'var(--color-medium-light-grey)',
        borderColor: 'var(--color-medium-light-grey)',
      },

      [`&.${buttonClasses.text}`]: {
        paddingRight: 0,
        paddingLeft: 0,
        paddingTop: '10px',
        paddingBottom: '20px',
        transition: colorTransition('color'),

        '&::after': {
          content: '" "',
          width: '100%',
          height: '2px',
          position: 'absolute',
          bottom: '10px',
          left: 0,
          transition: colorTransition('background-color'),
          transformOrigin: 'left',
          transform: 'scaleX(1)', // prevent rendering bugs
        },

        [`&.${buttonClasses.textPrimary}::after`]: {
          backgroundColor: theme.palette.primary.main,
        },

        [`&.${buttonClasses.textSecondary}::after`]: {
          backgroundColor: theme.palette.secondary.main,
        },

        '&.MuiButton-textInverted::after': {
          backgroundColor: 'var(--color-white)',
        },

        [`&.${buttonClasses.disabled}::after`]: {
          color: 'var(--color-medium-light-grey)',
          backgroundColor: 'var(--color-medium-light-grey)',
        },

        '&:hover, &:focus, .link-container:hover &, .link-container:focus &': {
          color: theme.palette.primary.main,
          backgroundColor: 'transparent',
          '&::after': {
            transform: 'scaleX(0.5)',
            backgroundColor: theme.palette.primary.main,
          },
        },

        '&.MuiButton-textInverted': {
          '&:hover, &:focus': {
            color: 'var(--color-white)',

            '&::after': {
              backgroundColor: 'var(--color-white)',
            },
          },
        },

        [`.${buttonClasses.startIcon}`]: {
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
  },
});

export default button;
