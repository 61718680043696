import React from 'react';

import { useVisibilityAnimation } from '@mewa/ui';

import { LinkData } from '../types';
import {
  SHOW_COLUMN_DELAY,
  VISIBILITY_ANIMATION_REMOVE_DELAY,
} from './animation-constants';
import { MenuColumn } from './link-columns';
import MenuWrapper from './menu-wrapper';

type Props = {
  langPrefix: string;
  show: boolean;
  columnStyle?: React.CSSProperties;
  columnData: MenuColumnData[];
  onMouseEnter: () => void;
};

const HeaderMenu = ({ langPrefix, show, columnData, onMouseEnter }: Props) => {
  const [renderUI, showUI] = useVisibilityAnimation(
    show,
    VISIBILITY_ANIMATION_REMOVE_DELAY,
  );

  return (
    <MenuWrapper
      renderUI={renderUI}
      showUI={showUI}
      show={show}
      onMouseEnter={onMouseEnter}
    >
      {renderMenuColumns(showUI, columnData, langPrefix)}
    </MenuWrapper>
  );
};

type MenuColumnData = {
  title?: string;
  links: LinkData[];
};

const renderMenuColumns = (
  showUI: boolean,
  columns: MenuColumnData[],
  langPrefix: string,
) => {
  const showColumnDelay = showUI ? SHOW_COLUMN_DELAY : 0;

  return columns
    .filter((column) => column.links.length > 0)
    .map((column, i) => {
      return (
        <MenuColumn
          key={i}
          title={column.title}
          show={showUI}
          langPrefix={langPrefix}
          links={column.links}
          transitionDelay={`${showColumnDelay + i * 80}ms`}
        />
      );
    });
};

export default HeaderMenu;
