import slugify from 'slugify';

import { Language } from './types';

slugify.extend({ '®': '', "'": '-', '’': '-' });

export const slugifyByLang = (s: string, lang: Language) =>
  slugify(s, {
    lower: true,
    strict: false,
    trim: true,
    locale: lang,
  });

export const slugifyByLocale = (s: string, locale: string) =>
  slugifyByLang(s, locale.split('-')[0] as Language);
