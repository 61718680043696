import { PageType, ServiceSegmentType } from '@mewa/types';

export type SearchResultGroups = Record<PageType, SearchResult[]>;

export type SearchSections = Pick<
  SearchResultGroups,
  PageType.productDetail | PageType.pressRelease
> & {
  brandTopic: SearchResult[];
  category: SearchResult[];
};

type PageFindResultBase = {
  id: string;
  score: number;
  words: number[];
  excerpt_range: number[];
};

export type SearchResultMeta = {
  title: string;
  image?: string;
  image_alt?: string;
  page_type: string;
};

export type ProductSearchResultMeta = SearchResultMeta & {
  product_is_clothing: string;
  product_collection: string;
  product_title: string;
  product_key_facts?: string;
  product_variants?: string;
  service_segment_name: string;
};

export type CategorySearchResultMeta = SearchResultMeta & {
  service_segment_type: ServiceSegmentType;
};

export type IndustrySearchResultMeta = SearchResultMeta & {
  service_segment_name: string;
  industry_name: ServiceSegmentType;
};

export type PressReleasesSearchResultMeta = SearchResultMeta & {
  date: string;
};

export type PageFindResultData<Meta = SearchResultMeta> = {
  content: string;
  excerpt: string;
  filters?: Record<string, string>;
  meta: Meta;
  raw_content?: string;
  raw_url?: string;
  url: string;
  word_count: number;
};

export type SearchResult = PageFindResultBase & PageFindResultData;

export type PageFindResult = PageFindResultBase & {
  data: () => Promise<PageFindResultData>;
};

export type PageFindSearchResult = { results: PageFindResult[] } | undefined;

export interface PageFindInstance {
  init: () => Promise<void>;
  search: (searchQuery: string) => Promise<PageFindSearchResult>;
}

export const searchResultsAreProducts = (
  results: PageFindResultData[],
): results is PageFindResultData<ProductSearchResultMeta>[] =>
  results.every((r) => r.meta.page_type === PageType.productDetail);

export const searchResultIsCategory = (
  result: PageFindResultData,
): result is PageFindResultData<CategorySearchResultMeta> =>
  result.meta.page_type === PageType.category;

export const searchResultIsIndustry = (
  result: PageFindResultData,
): result is PageFindResultData<IndustrySearchResultMeta> =>
  result.meta.page_type === PageType.industry;

export const searchResultIsCollection = (result: PageFindResultData) =>
  result.meta.page_type === PageType.collection;

export const searchResultsArePressReleases = (
  results: PageFindResultData[],
): results is PageFindResultData<PressReleasesSearchResultMeta>[] =>
  results.every((r) => r.meta.page_type === PageType.pressRelease);
