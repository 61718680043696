import React from 'react';

import { Link as GatsbyLink } from 'gatsby';

import { styled } from '@mui/material';

import { Market } from '@mewa/types';
import { cssVars, LogoDesktop, LogoMobile } from '@mewa/ui';

import { NavigationData } from './types';

export type HeaderBaseProps = {
  langPrefix: string;
  navigationData: NavigationData;
  market: Market;
  piwikEventNameForHeaderCTAButton?: string;
  showCTA?: boolean;
};

export const OPEN_QUICK_NAVIGATION_EVENT = 'openquicknavigation';

export const MewaLogo = ({ langPrefix }: { langPrefix: string }) => {
  return (
    <LogoLink to={langPrefix || '/'} aria-label="mewa-logo">
      <LogoDesktop className="header-logo-desktop" />
      <LogoMobile className="header-logo-mobile" />
    </LogoLink>
  );
};

const LogoLink = styled(GatsbyLink)(({ theme }) => ({
  marginRight: '20px',
  'svg.header-logo-desktop': {
    height: '28px',
  },
  '.header-logo-mobile': {
    display: 'none',
  },

  [theme.breakpoints.down('md')]: {
    '.header-logo-mobile': {
      display: 'block',
    },
    '.header-logo-desktop': {
      display: 'none',
    },
  },

  [theme.breakpoints.up(1680)]: {
    marginRight: '40px',
  },

  [theme.breakpoints.down(cssVars.mobileSearchMaxWidth)]: {
    '.search-has-focus &': {
      display: 'none',
    },
  },
}));

export const HeaderWrapper = styled('div')(({ theme }) => ({
  height: 'calc(var(--header-height) - 1px)', // We must keep 1px for the bottom border of the header
  display: 'grid',
  gridTemplateColumns: 'auto auto',
  gridTemplateRows: '64px 60px',
  justifyContent: 'space-between',
  alignItems: 'center',

  [theme.breakpoints.down(cssVars.mobileSearchMaxWidth)]: {
    '.search-has-focus &': {
      gridTemplateRows: '0 auto',
      height: 'auto',
    },
  },

  [theme.breakpoints.up(cssVars.mobileSearchMaxWidth)]: {
    gridTemplateColumns: 'auto 1fr auto',
    gridTemplateRows: 'auto',
  },
}));

export const BaseNavigation = styled('nav')({
  height: '100%',
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'flex-end',
  flexWrap: 'wrap',
  position: 'relative',
});

export const wrapperStyles = {
  height: '100%',
  backgroundColor: 'white',
};
