import React, { useState } from 'react';

import {
  Accordion,
  accordionClasses,
  AccordionDetails,
  AccordionSummary,
  accordionSummaryClasses,
  Grid,
  paperClasses,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { ArrowRightIcon } from '../../../assets/icons';
import ResponsiveGrid from '../../layout/grid';
import { useSectionsHeightAnimation } from './use-sections-height-animation';

export type SlotSection = {
  name: string;
  content: React.ReactElement;
};

export const TabbedSlots = ({
  sections,
  className = '',
  ...dataProps
}: {
  sections: [SlotSection, ...SlotSection[]];
  className?: string;
  [key: `data-${string}`]: string;
}) => {
  const [activeSectionIndex, setActiveSectionIndex] = useState(0);
  const [expand, setExpand] = useState(false);

  const theme = useTheme();

  // Since `useMediaQuery` initially returns false, we have to invert the
  // values to avoid showing empty tabbed slots.
  // To avoid displaying both (accordion and tabs) we additionally use
  // css to show only the relevant variant
  const showAccordion = useMediaQuery(theme.breakpoints.down(1280));

  const container = React.useRef<HTMLDivElement>(null);

  useSectionsHeightAnimation(400, activeSectionIndex, container);

  const handleAccordionChange = (sectionIndex: number) => {
    setActiveSectionIndex((currentActive) => {
      if (currentActive === sectionIndex && expand) {
        setExpand(false);
      } else {
        setExpand(true);
      }
      return sectionIndex;
    });
  };

  return (
    <Container {...dataProps}>
      {showAccordion ? (
        <AccordionContainer className={className}>
          <Grid item xs={12}>
            {sections.map((section, index) => (
              <StyledAccordion
                key={index}
                expanded={expand && activeSectionIndex === index}
                onChange={() => handleAccordionChange(index)}
              >
                <AccordionSummary>{section.name}</AccordionSummary>
                <StyledAccordionDetails>
                  {section.content}
                </StyledAccordionDetails>
              </StyledAccordion>
            ))}
          </Grid>
        </AccordionContainer>
      ) : (
        <TabsContainer className={className}>
          <Grid item xs={4} md={3}>
            <SectionTitles>
              {sections.map((section, index) => {
                const isActive = activeSectionIndex === index;

                return (
                  <li key={index}>
                    <SectionLink
                      isActive={isActive}
                      onClick={() => setActiveSectionIndex(index)}
                    >
                      {section.name}
                      <StyledActiveSectionIndicator isActive={isActive}>
                        <ArrowRightIcon />
                      </StyledActiveSectionIndicator>
                    </SectionLink>
                  </li>
                );
              })}
            </SectionTitles>
          </Grid>
          <Grid item xs={8} md={9} ref={container}>
            {sections.map((section, index) => (
              <SectionContent key={index}>{section.content}</SectionContent>
            ))}
          </Grid>
        </TabsContainer>
      )}
    </Container>
  );
};

const Container = styled('div')(({ theme }) => ({
  marginTop: '9px',

  [theme.breakpoints.up('sm')]: {
    marginTop: '48px',
  },

  [theme.breakpoints.up(1280)]: {
    marginTop: '96px',
  },

  [theme.breakpoints.up(1680)]: {
    marginTop: '124px',
  },
}));

const AccordionContainer = styled(ResponsiveGrid)(({ theme }) => ({
  [theme.breakpoints.up(1280)]: {
    display: 'none',
  },
}));

const TabsContainer = styled(ResponsiveGrid)(({ theme }) => ({
  [theme.breakpoints.down(1280)]: {
    display: 'none',
  },
}));
const StyledAccordion = styled(Accordion)({
  [`&.${paperClasses.root}.${accordionClasses.root}:first-of-type > .${accordionSummaryClasses.root}`]:
    {
      borderTop: 'none',
    },
});

const StyledActiveSectionIndicator = styled('span')(
  ({ isActive }: { isActive: boolean }) => ({
    opacity: isActive ? 1 : 0,
    transition: 'opacity var(--transition-duration)',
  }),
);

const StyledAccordionDetails = styled(AccordionDetails)({
  padding: '23px 0 50px',
});

const SectionTitles = styled('ul')(({ theme }) => ({
  listStyle: 'none',
  paddingRight: '5vw',
  paddingLeft: 0,
  paddingTop: '11px',
  margin: 0,

  [theme.breakpoints.up(1200)]: {
    paddingTop: '7px',
  },
}));

const SectionLink = styled('span')(
  ({ isActive }: { isActive: boolean }) =>
    ({ theme }) => ({
      ...theme.typography.h6,
      fontWeight: theme.typography.fontWeightMedium,
      margin: 0,
      color: isActive ? 'var(--color-light-red)' : 'var(--color-dark-coal)',
      display: 'inline-flex',
      border: 'none',
      cursor: isActive ? 'auto' : 'pointer',
      marginBottom: '30px',
      transition: 'color var(--transition-duration)',

      svg: {
        fill: 'currentColor',
        height: '12px',
        width: '8px',
        marginLeft: '30px',

        [theme.breakpoints.down(1200)]: {
          height: '10px',
          width: '7px',
        },
      },

      '&:hover': {
        color: 'var(--color-light-red)',
      },

      [theme.breakpoints.down(1780)]: {
        marginBottom: '24px',
      },
    }),
);

const SectionContent = styled('div')({
  maxWidth: '1300px',
  overflow: 'hidden',
});
